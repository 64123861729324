import { useSignal } from "@preact/signals";

export default function RegisterCodeCheck({login, password, code, changeForm}: {login: string, password: string, code: string, changeForm: (a: string) => void}) {
    const enteredCode = useSignal("")
    const error = useSignal(false)

    async function sendCode() {
        if (enteredCode.value != code) {
            error.value = true
            return
        }

        await fetch("/api/login", {
            method: "OPTIONS",
            body: JSON.stringify({
                login, password        
            })
        })

        await fetch("/api/login", {
            method: "POST",
            body: JSON.stringify({
                login, password
            })
        })

        window.location.replace("/personaldata")
    }

    return (
        <div class="d-flex flex-column mx-auto my-3 register w-[452px]">
            <h3 class="text-center mb-0 text-2xl font-semibold">Подтверждение e-mail</h3>
            <div class="d-flex flex-column min-h-[500px] pb-3 shadow gap-[22px] mt-[27px] px-[12px]">
                <p class="text-center fw-medium fs-6 mt-3" style="color: #4a4a4a">На ваш e-mail был отправлен код подтверждения введите его в поле ниже</p> 
                {error.value && <div class="alert alert-danger text-center mx-auto rounded-0 mb-0">Что-то не так! Попробуйте еще раз!</div>}

                <input type="number" placeholder="Код" class="enter-form appearance-off" value={enteredCode.value} onInput={e => enteredCode.value = e.currentTarget.value} />
                <div class="d-flex justify-content-end">
                    <button onClick={() => sendCode()} class="btn-black" style="padding: 11px 24px">Подтвердить</button>
                </div>
            </div>
        </div>
    )
}